import React, { useEffect, useState } from "react";
import ProjectGallery from "./Details/ProjectGallery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import WindowIcon from "@mui/icons-material/Window";
import LogoutIcon from "@mui/icons-material/Logout";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import PaperContainer from "../PaperContainer/PaperContainer";
import DownloadIcon from "@mui/icons-material/Download";
import { UseIsAboveSm } from "src/helperHooks";
import ContactDialog from "./conatctDetailsPdfDownlaod";

const logos = [
  AccountBalanceIcon,
  BackupTableIcon,
  AutoFixHighIcon,
  WindowIcon,
  LogoutIcon,
  MeetingRoomIcon,
  ElectricalServicesIcon,
];

const REDetails = (props) => {
  const { state } = useLocation();
  const isAboveSm = UseIsAboveSm();

  const estate = state.property;
  const loading = state.loading;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const baseImageBucketUrl = process.env.REACT_APP_S3_IMAGE_BUCKET_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!estate) {
    return <Typography variant="h4">Home not found</Typography>;
  }
  return (
    <PaperContainer>
      {estate.displayDetailsPageFlag ? (
        loading ? (
          <div>Loading...</div>
        ) : (
          <Box px={2} py={4}>
            <Typography variant="h4" gutterBottom>
              {estate.projectTitle}
            </Typography>
            <Box>
              <img
                style={{ maxWidth: "100%" }}
                src={`${baseImageBucketUrl}${estate.projectImage}`}
              />
              <Typography variant="h4" gutterBottom py={2}>
                Introducing {estate.projectTitle}
              </Typography>
              <Typography variant="body1" paragraph>
                {estate.projectInfo}
              </Typography>

              <Typography variant="subtitle1">
                {estate.registrationNumber}
              </Typography>
            </Box>
            <Box py={2}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<DownloadIcon />}
                onClick={handleOpen}
              >
                DOWNLOAD BROCHURE
              </Button>
              <ContactDialog open={open} handleClose={handleClose} />
            </Box>
            <Box py={2}>
              <Typography variant="h5" gutterBottom>
                Property Features
              </Typography>
              <Grid container spacing={2}>
                {estate.details.propertiesFeatures.map((feature, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <FiberManualRecordIcon color="" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box py={2}>
              <Typography variant="h5" gutterBottom>
                Property Specifications
              </Typography>
              <Grid container spacing={2} py={2}>
                {estate?.details.propertiesSpecification.map((spec, index) => {
                  const LogoComponent = logos[index % logos.length];
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box
                        p={1}
                        border={1}
                        borderRadius={2}
                        borderColor="grey.400"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <LogoComponent />
                        {/* <img src={spec.logo} /> */}
                        <Typography variant="h6" textAlign={"center"}>
                          {spec.title}
                        </Typography>
                        <Typography variant="subtitle2" textAlign={"center"}>
                          {spec.shortDescription}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <ProjectGallery gallery={estate?.details.imageGallery} />
          </Box>
        )
      ) : (
        <Box sx={{ color: "primary.main" }}>
          <Typography variant="h4" textAlign={"center"}>
            {estate.projectTitle} will be {estate.status} !!!
          </Typography>
        </Box>
      )}
    </PaperContainer>
  );
};

export default REDetails;
